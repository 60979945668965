<template>
  <footer class="main-footer bg-black-30 pt-4 md:pt-10 pb-14 border-t-1 border-gray-50">
    <div class="container mx-auto">
      <client-only>
        <nd-collapse v-if="isShowMobileMenu" class="mobile-links" :list="footerLinks" header-class="text-gray-10 py-3 -mt-4" :accordion="true">
          <template #content="{ item }">
            <div class="flex flex-col">
              <template v-for="link in item.links" :key="link.text">
                <locale-link
                  v-if="link.show"
                  v-bind="link['props']"
                  class="block px-5 mb-6 ml-4 no-underline text-lg text-gray-10 hover:text-yellow-50 transition-colors"
                >
                  {{ link['text'] }}
                </locale-link>
              </template>
            </div>
          </template>
        </nd-collapse>
        <div v-else class="links flex gap-6 px-4 mb-32">
          <template v-for="group in footerLinks" :key="group.title">
            <div class="links-group flex flex-col items-start w-full gap-4">
              <div class="links-group-title mb-1">
                <span class="text-xl text-gray-50 cursor-default">{{ group.title }}</span>
              </div>
              <template v-for="link in group.links" :key="link.text">
                <locale-link v-if="link.show" v-bind="link.props" class="no-underline text-lg text-gray-10 hover:text-yellow-50 transition-colors">
                  {{ link.text }}
                </locale-link>
              </template>
            </div>
          </template>
        </div>
      </client-only>
      <n-divider class="@sm:px-0 px-4 my-4" />
      <div class="brand @sm:px-0 px-4 md:flex-row flex flex-col-reverse justify-between gap-3">
        <div class="logo flex justify-between items-end gap-2 md:justify-start md:items-center md:gap-4">
          <nd-img src="@/assets/images/logo.svg" alt="Niceday Logo" class="w-120px h-10 text-yellow-50" />
          <span class="text-xs md:text-sm text-gray-50">{{ copyrightText }}</span>
        </div>
        <div class="socials md:flex-row flex flex-row-reverse justify-end gap-3">
          <template v-for="social in SOCIALS" :key="social.src">
            <n-button v-if="social.show" text tag="a" :href="social.href">
              <nd-img :src="social.src" class="w-6 h-6 text-gray-10 hover:opacity-75 md:w-10 md:h-10" />
            </n-button>
          </template>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { NButton, NDivider } from 'naive-ui'
import { useAppStore } from '@/store/app'
import { LangEnum } from '@/i18n.config'

import { faqPageLanguageMap, jobVacancyLanguageMap } from '@/constants/locales'
import { facebookLinkMap, instagramLinkMap, podcastLinkMap } from '@/constants/regions'

import { useRegionStore } from '@/store/region'
import { RegionIdMap } from '@/types/region'

const { t, locale } = useI18n()

const regionStore = useRegionStore()

const copyrightText = `© ${new Date().getFullYear()} Zoek. All rights reserved.`

const regionId = computed(() => regionStore.currentRegionId ?? RegionIdMap.TAIWAN)

const footerLinks = computed(() => [
  {
    title: t('footer_about_us'),
    links: [
      { text: t('footer_about_niceday'), props: { to: '/about' }, show: true },
      {
        text: t('footer_theme_special_collection'),
        props: { href: 'https://www.event.niceday.tw/', target: '_blank' },
        show: locale.value === LangEnum.TW,
      },
      { text: t('footer_press_reports'), props: { to: { path: '/about', hash: '#media' } }, show: locale.value === LangEnum.TW },
    ],
    custom: true,
  },
  {
    title: t('footer_join_us'),
    links: [
      { text: t('footer_vender_join_us'), props: { to: '/corporation' }, show: true },
      {
        text: t('footer_job_vacancy'),
        props: { href: `https://www.cakeresume.com/companies/funnow?locale=${jobVacancyLanguageMap[locale.value]}`, target: '_blank' },
        show: true,
      },
      {
        text: t('footer_chartered_activity_for_corp'),
        props: { href: 'https://www.events.myfunnow.com/company-welfare', target: '_blank' },
        show: locale.value === LangEnum.TW,
      },
    ],
    custom: true,
  },
  {
    title: t('footer_site_info'),
    links: [
      {
        text: t('footer_faq'),
        props: { href: `https://help.niceday.tw/portal/${faqPageLanguageMap[locale.value]}/kb/niceday`, target: '_blank' },
        show: true,
      },
      { text: t('footer_service_agreement'), props: { to: '/policy' }, show: true },
      { text: t('footer_privacy_policy'), props: { to: '/privacy' }, show: true },
    ],
    custom: true,
  },
])

const SOCIALS = computed(() => [
  {
    src: '/images/little_red_book_logo.png',
    href: 'https://www.xiaohongshu.com/user/profile/64c08917000000001403f325',
    show: regionId.value === RegionIdMap.MALAYSIA,
  },
  { src: '@/assets/icons/footer-podcast.svg', href: podcastLinkMap[regionId.value], show: true },
  { src: '@/assets/icons/footer-instagram.svg', href: instagramLinkMap[regionId.value], show: true },
  { src: '@/assets/icons/footer-facebook.svg', href: facebookLinkMap[regionId.value], show: true },
])

const app = useAppStore()
const isShowMobileMenu = computed(() => app.isMobile || app.isTablet)
</script>

<style lang="scss" scoped></style>
