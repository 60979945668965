import { RegionIdMap } from '@/types/region'

function genRegionMap(originMap: Record<number, string>) {
  return genObjectWithDefaultKey(originMap, RegionIdMap.TAIWAN)
}

export const facebookLinkMap = genRegionMap({
  [RegionIdMap.TAIWAN]: 'https://www.facebook.com/niceday.tw/',
  [RegionIdMap.MALAYSIA]: 'https://www.facebook.com/profile.php?id=61551945897211',
})

export const instagramLinkMap = genRegionMap({
  [RegionIdMap.TAIWAN]: 'https://www.instagram.com/niceday_kids/',
  [RegionIdMap.MALAYSIA]: 'https://www.instagram.com/niceday_my/',
})

export const podcastLinkMap = genRegionMap({
  [RegionIdMap.TAIWAN]: 'https://linktr.ee/niceday_kids',
  [RegionIdMap.MALAYSIA]:
    'https://podcasts.apple.com/tw/podcast/have-a-niceday-%E9%99%AA%E4%BD%A0%E6%8E%A2%E7%B4%A2%E5%AD%A9%E5%AD%90%E7%9A%84%E7%84%A1%E9%99%90%E5%8F%AF%E8%83%BD/id1654091761',
})
